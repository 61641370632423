.footer {
  position: relative;
  width: 100vw;
  height: 55vh;
  background-color: rgba(19, 19, 19, 0.8);
  overflow: hidden;
}

.footer-container {
  margin-left: 5%;
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.left {
  font-size: 1.4vw;
  font-weight: 400;
  position: absolute;
  height: 100%;
  top: 15%;
  left: -5%;
  width: 55%;
  display: flex;
  flex-direction: column;
  margin-right: 2.5%;
  margin-top: 7%;
}
.location {
  width: 100%;
  display: flex;
  margin: 0px 30px;
  justify-content: flex-start;
  align-items: center;
}
.phone {
  width: 100%;
  display: flex;
  margin: 0px 30px;
  justify-content: flex-start;
  align-items: center;
}
.email {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  margin: 0px 30px;
}
.right {
  position: absolute;
  height: 100%;
  top: 15%;
  right: 0%;
  width: 55%;
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
  margin-top: 7%;
}
.right h4 {
  position: absolute;
  left: 50%;
  font-size: 1.8vw;
  align-items: center;
  text-align: center;
  transform: translateX(-50%);
}
.socials {
  display: flex;
  flex-wrap: wrap;
  width: 130%;
  margin-top: 7%;
}
@media screen and (max-width: 640px) {
  .left .location div {
    font-size: 3vw;
    margin-left: 35px;
  }
  .left .phone {
    margin-left: 15px;
  }
  .left .phone h4 {
    font-size: 3vw;
    margin-left: 30px;
  }
  .left .email h4 {
    font-size: 3vw;
    margin-left: 35px;
  }
  .right h4 {
    font-size: 3vw;
  }
  .footer .left {
    margin-left: -40px;
  }
}
